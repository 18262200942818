import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

// Monstera icon made by Freepik from https://www.flaticon.com
export const MonsteraIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <g transform="translate(-20 40) scale(0.8)">
        <path d="m499.466 212.336-57.62-9.603c-7.082-1.181-13.206-5.382-16.959-11.238l25.025-1.472c23.805-1.4 44.245-15.052 54.678-36.52 10.432-21.464 8.538-45.967-5.066-65.546-20.535-29.554-45.93-54.95-75.48-75.483-19.582-13.604-44.086-15.498-65.545-5.066-21.468 10.433-35.12 30.874-36.521 54.679l-1.473 25.027c-5.855-3.752-10.058-9.876-11.237-16.959l-9.604-57.62c-1.268-7.608-8.104-13.006-15.794-12.501l-28.578 1.905c-23.355 1.557-44.232 13.596-57.279 33.029-13.047 19.434-16.284 43.315-8.883 65.521l7.174 21.522-4.617 3.078c-3.631 2.421-7.952 3.139-12.171 2.017-4.218-1.12-7.614-3.886-9.566-7.789l-27.23-54.457c-2.156-4.313-6.254-7.326-11.014-8.098-4.754-.773-9.6.791-13.009 4.2l-38.743 38.743c-14.788 14.788-22.771 35.254-21.902 56.149.869 20.896 10.524 40.628 26.489 54.138l31.547 26.693c-6.415 5.959-15.114 9.125-24.233 8.42l-60.395-4.645c-4.368-.333-8.661 1.253-11.757 4.35-3.097 3.096-4.686 7.391-4.35 11.757l2.81 36.525c1.454 18.898 9.913 36.398 23.82 49.277s32.005 19.971 50.959 19.971h35.505l177.946-177.946c5.857-5.858 15.355-5.858 21.213 0s5.858 15.355 0 21.213l-177.946 177.946v35.504c0 18.954 7.093 37.051 19.971 50.958s30.378 22.367 49.276 23.82l36.526 2.81c.385.029.769.044 1.151.044 3.962 0 7.782-1.57 10.605-4.394 3.097-3.096 4.686-7.391 4.35-11.757l-4.646-60.393c-.701-9.113 2.462-17.819 8.421-24.233l26.692 31.545c13.509 15.965 33.241 25.62 54.137 26.49 20.873.862 41.361-7.114 56.15-21.902l38.744-38.744c3.409-3.409 4.973-8.25 4.2-13.009s-3.786-8.857-8.099-11.014l-54.457-27.229c-3.902-1.952-6.669-5.349-7.789-9.566s-.403-8.54 2.017-12.17l3.079-4.618 21.521 7.174c22.202 7.403 46.087 4.165 65.521-8.882s31.473-33.925 33.029-57.28l1.905-28.578c.515-7.695-4.891-14.525-12.498-15.793z" />
        <path d="m128.448 362.34-124.054 124.054c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.767 4.393 10.606 4.393s7.678-1.464 10.606-4.394l124.054-124.053v-21.213z" />
      </g>
      <g transform="translate(380 340) scale(0.3)">
        <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
      </g>
    </SvgIcon>
  )
}

export default MonsteraIcon
