import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

// Icon made by Freepik from https://www.flaticon.com
export const WateringCanLeafIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 511.998 511.998">
      <path d="m386.225 210.737-127.27-127.28c-5.448-5.449-14.141-5.885-20.105-1.004l-21.699 17.753c-6.781-23.622-21.21-41.976-41.13-51.264-22.666-10.57-49.224-8.441-74.778 5.995-23.919 13.512-44.34 36.499-57.503 64.727-20.229 43.381-19.113 90.577 1.445 121.233l-39.685 32.467c-6.87 5.62-7.377 15.949-1.108 22.216l169.71 169.71c6.28 6.281 16.604 5.753 22.217-1.108l190.909-233.339c4.879-5.965 4.446-14.657-1.003-20.106zm-317.68 11.048c-13.143-22.001-12.664-57.172 2.384-89.443 10.543-22.609 26.549-40.823 45.069-51.286 16.887-9.54 33.699-11.289 47.345-4.925 15.09 7.037 25.113 23.792 27.563 45.547z" />
      <path d="m486.499 257.86-34.501 33.811v101.916l34.394 34.393c9.383 9.386 25.606 2.798 25.606-10.606v-148.8c0-13.257-16.022-20.001-25.499-10.714z" />
      <path d="m279.578 409.793 142.42-20.148v-94.526l-43.587-6.124z" />
      <g transform='translate(290 -30) scale(10)'>
        <path d="M6.05,8.05c-2.73,2.73-2.73,7.15-0.02,9.88c1.47-3.4,4.09-6.24,7.36-7.93c-2.77,2.34-4.71,5.61-5.39,9.32 c2.6,1.23,5.8,0.78,7.95-1.37C19.43,14.47,20,4,20,4S9.53,4.57,6.05,8.05z" />
      </g>
    </SvgIcon>
  )
}

export default WateringCanLeafIcon
