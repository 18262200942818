export enum SortingMode {
  DAYS_TO_WATER = 'days to water',
  WATER = 'wateringDates',
  FERTILIZE = 'fertilizingDates',
  NAME = 'name',
  INTERVAL = 'interval',
}

export enum SortingDirection {
  ASC = 'ascending',
  DESC = 'descending'
}
